<!-- eslint-disable vue/html-self-closing -->
<template>
    <div
        class="teamtailor-jobs-widget"
        :data-teamtailor-limit="props.model.jobLimit"
        data-teamtailor-api-key="NXwX4FJQ9k0lZ9nAO4p0iD8xuwExxpJwxbl4wsBr"
    ></div>
</template>

<script setup lang="ts">
import { IJobWidgetBlockModel } from '@/types'

const props = defineProps<{
    model: IJobWidgetBlockModel
}>()

const tag = document.createElement('script')
tag.src = 'https://scripts.teamtailor-cdn.com/widgets/eu-pink/jobs.js'
const firstScriptTag = document.getElementsByTagName('script')[0]
firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
</script>

<style lang="scss"></style>
